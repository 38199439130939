.cta-button {
    display: inline-block;
    padding: 12px 25px;
    font-size: 14px;
    background-color: var(--orange);
    width: max-content;
    margin-top: 10px;
    color: white;
    font-weight: 500;
    letter-spacing: .5px;
    border-radius: 4px;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
}

.cta-button:hover {
    background-color: #b13700;
}

.cta-button.large {
    line-height: 26px;
    padding: 16px 36px;
    font-weight: 700;
    letter-spacing: 1px;
    border-radius: 8px;
}

@media (max-width: 610px) {
    .cta-button:not(.short) {
        width: 100%;
        text-align: center;
    }
}