.fd-form {
    background-color: #f7f9fc !important;
    padding-bottom: 60px;
    position: relative;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f input[name="email"]+.label-text+.fd-form-feedback::after {
    content: " Format must follow: mail@example.com";
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f *,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f *::before,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f *::after {
    box-sizing: border-box;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [tabindex="-1"]:focus {
    outline: none !important;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f h1,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f h2,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f h3,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f h4,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f h5,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f h6 {
    margin-top: 0;
    margin-bottom: 0.7em;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f p {
    margin-top: 0;
    margin-bottom: 1rem;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f ol,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f ul,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f dl {
    margin-top: 0;
    margin-bottom: 1.4rem;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f ol ol,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f ul ul,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f ol ul,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f ul ol {
    margin-bottom: 0;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f strong {
    font-weight: bolder;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f small {
    font-size: 80%;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f sub,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f sub {
    bottom: -0.25em;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f sup {
    top: -0.5em;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f {
    color: #000000;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f a:hover {
    color: #4396fd;
    text-decoration: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f img {
    border-style: none;
    vertical-align: middle;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f svg:not(:root) {
    overflow: hidden;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f area,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f button,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [role="button"],
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f input,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f label,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f select,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f summary,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f textarea {
    touch-action: manipulation;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f label {
    display: inline-block;
    font-weight: bolder;
    margin-bottom: 0.7rem;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f button:focus {
    outline: 1px dotted;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f input,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f button,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f select,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f optgroup,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f textarea {
    margin: 0;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f button,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f input {
    overflow: visible;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f button,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f select {
    text-transform: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f button,
html[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [type="button"],
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [type="reset"],
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [type="submit"] {
    -webkit-appearance: button;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f button::-moz-focus-inner,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [type="button"]::-moz-focus-inner,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [type="reset"]::-moz-focus-inner,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f input[type="radio"],
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f input[type="checkbox"] {
    padding: 0;
    box-sizing: border-box;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f input[type="date"],
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f input[type="time"],
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f input[type="datetime-local"],
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f input[type="month"] {
    -webkit-appearance: listbox;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f textarea {
    resize: vertical;
    overflow: auto;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [type="number"]::-webkit-inner-spin-button,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [type="search"]::-webkit-search-cancel-button,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f [hidden] {
    display: none !important;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-control {
    width: 100%;
    display: block;
    outline: none;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-control:focus {
    outline: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-control::placeholder {
    /* color: transparent !important; */
    /* opacity: 0 !important; */
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-control:disabled {
    opacity: 1;
}


[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-label>.label-text {
    z-index: 2;
    top: -25px;
    left: 10px;
    right: 0;
    margin: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-description {
    margin: 5px 0 0 0;
    font-size: 0.8em;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-feedback {
    margin: 5px 0 0 0;
    font-size: 0.8em;

    color: #e00202 !important;

}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-group {
    margin: 0 0 15px;
    position: relative;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-group.fd-has-success .fd-form-feedback,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-group.fd-has-success .fd-form-check {
    color: #02dba8 !important;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-group.fd-has-success .fd-form-control {
    color: #02dba8 !important;
    border-color: #02dba8 !important;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-group.fd-has-success .fd-form-feedback {
    display: block;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-group.fd-has-error .fd-form-feedback,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-group.fd-has-error .fd-form-check {
    color: #e00202 !important;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-group.fd-has-error .fd-form-control {
    color: #e00202 !important;
    border-color: #e00202 !important;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-group.fd-has-error .fd-form-feedback {
    display: block;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-btn {
    cursor: pointer;
    display: inline-flex;
    outline: none;
    max-width: 100%;
    -webkit-appearance: none;
    appearance: none;
    font-style: normal;
    text-align: center;
    align-items: center;
    text-shadow: none;
    white-space: normal;
    justify-content: center;
    text-decoration: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-btn:hover {
    outline: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-btn:focus {
    outline: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-btn:disabled {
    opacity: 0.8;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-check {
    cursor: pointer;
    margin: 0;
    display: flex;
    position: relative;
    align-items: center;
    padding-left: 30px;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-check__input {
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    opacity: 0;
    z-index: -1;
    position: absolute;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-check__checkmark {
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    display: block;
    position: absolute;
    background-size: 18px;
    background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' %3e %3cpath d='M1 4C1 2.34315 2.34315 1 4 1H16C17.6569 1 19 2.34315 19 4V16C19 17.6569 17.6569 19 16 19H4C2.34315 19 1 17.6569 1 16V4Z' fill='white' /%3e %3cpath fill='black' fill-rule='evenodd' clip-rule='evenodd' d='M0.25 4C0.25 1.92893 1.92893 0.25 4 0.25H16C18.0711 0.25 19.75 1.92893 19.75 4V16C19.75 18.0711 18.0711 19.75 16 19.75H4C1.92893 19.75 0.25 18.0711 0.25 16V4ZM4 1.75C2.75736 1.75 1.75 2.75736 1.75 4V16C1.75 17.2426 2.75736 18.25 4 18.25H16C17.2426 18.25 18.25 17.2426 18.25 16V4C18.25 2.75736 17.2426 1.75 16 1.75H4Z' /%3e %3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center center;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-check__input:checked+.fd-form-check__checkmark::after {
    opacity: 1;
    z-index: 1;
    visibility: visible;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-check__checkmark::after {
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    content: "";
    display: block;
    opacity: 0;
    z-index: 1;
    position: absolute;
    transition: opacity 0.4s, z-index 0.4s;
    visibility: inherit;
    background-size: 12px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='black' width='12' height='10' viewBox='0 0 11.51 8.2' %3e %3ctitle%3echeck%3c/title%3e %3cpath d='M4.05%2c8.2A.74.74%2c0%2c0%2c1%2c3.52%2c8L.22%2c4.68A.75.75%2c0%2c0%2c1%2c1.28%2c3.62l3.3%2c3.3A.75.75%2c0%2c0%2c1%2c4.58%2c8%2c.74.74%2c0%2c0%2c1%2c4.05%2c8.2Z' /%3e %3cpath d='M4.06%2c8.2A.74.74%2c0%2c0%2c1%2c3.53%2c8a.75.75%2c0%2c0%2c1%2c0-1.06l6.7-6.7a.75.75%2c0%2c0%2c1%2c1.06%2c1.06L4.59%2c8A.74.74%2c0%2c0%2c1%2c4.06%2c8.2Z' /%3e %3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center center;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-check__input:focus {
    outline: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-content {
    position: relative;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-has-success .fd-form-content {
    display: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-has-captcha .fd-form-content>*:not(.fd-form-captcha) {
    opacity: 0;
    visibility: hidden;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-captcha {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: flex-start;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-captcha>div:not([style*='display: none;']) {
    left: 50%;
    translate: -50%;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-captcha>div[style*='display: none;'] {
    display: none;
}


[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-success {
    width: 100%;
    display: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-has-success .fd-form-success {
    display: block;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-success>*:last-child {
    margin-bottom: 0;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-error {
    display: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-has-error .fd-form-error {
    display: block;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-error>*:last-child {
    margin-bottom: 0;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-focus-visible,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-check__input.fd-focus-visible+.fd-form-check__checkmark {
    outline: none;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 calc(2px + 4px) #000000 !important;
    transition: box-shadow 0.2s !important;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-focus-visible,
[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-check__input.fd-focus-visible+.fd-form-check__checkmark {
    outline: none;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 calc(2px + 2px) #717171 !important;
    transition: box-shadow 0.2s !important;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f {
    background: transparent;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__container {
    margin: 0 auto;
    max-width: 1170px;
    background: transparent;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__form {
    color: var(--dark-blue);
    width: 100%;
    margin: 0;
    padding: 30px;
    font-size: 16px;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 0.1px;
    text-transform: none;
}

@media (max-width: 767px) {
    [data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__form {
        word-wrap: anywhere;
        word-break: break-word;
        white-space: normal;
        overflow-wrap: break-word;
    }
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__title {
    color: #000000;
    width: 100%;
    margin: 0 0 25px;
    display: block;
    font-size: 37px;
    text-align: center;
    font-family: Inter;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0px;
    text-transform: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__title * {
    line-height: inherit;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__subtitle {
    width: 100%;
    margin: 0 0 30px;
    display: block;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__subtitle * {
    line-height: inherit;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__content {
    margin: -10px -5px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 767px) {
    [data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__content {
        display: block;
    }
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f[data-ff-stage="success"] .ff-637662ccd6bfc824ac69bc5f__content {
    display: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__fields {
    flex: 0 1 auto;
    margin: 0px 0px 0;
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% + 10px);
    justify-content: center;
}

@media (max-width: 767px) {
    [data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__fields {
        display: block;
    }
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__field {
    flex: 1 1;
    margin: 10px 5px 0;
    font-size: 13px;
    max-width: 250px;
    min-width: 140px;
    text-align: left;
    font-family: Inter;
    font-weight: 400;
    letter-spacing: 0.1px;
}

@media (max-width: 767px) {
    [data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__field {
        max-width: calc(100% - 10px);
    }

    .fd-form-label {
        width: 100%;
        margin-top: 10px;
    }

    [data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .fd-form-label>.label-text {
        top: -15px;
    }
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__control {
    color: #000000;
    border: 1px solid #dddddd;
    height: 46px;
    padding: 12px 20px;
    font-size: 13px;
    background: #ffffff;
    text-align: left;
    font-family: Inter;
    font-weight: 400;
    line-height: 20px;
    border-radius: 4.6000000000000005px;
    letter-spacing: 0.1px;
    text-transform: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__label {
    color: #000000;
    border: 1px solid transparent;
    padding: 12px 20px;
    font-size: 13px;
    text-align: left;
    font-family: Inter;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-transform: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__preference {
    margin: 30px 5px 0;
    display: flex;
    flex-direction: column;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__preference-title {
    /* color: #333333; */
    width: 100%;
    margin: 0 0 30px;
    display: block;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0px;
    text-transform: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__preference-title * {
    line-height: inherit;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__preference-control {
    width: 100%;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__preference-list {
    width: calc(100% + 24px);
    margin: -8px -12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

@media (max-width: 767px) {
    [data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__preference-list {
        display: block;
    }
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__preference-item {
    flex: 0 0 33.333333333333336%;
    padding: 8px 12px;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__form-check .fd-form-check__input {
    top: 2.200000000000001px;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__form-check .fd-form-check__checkmark {
    top: 2.200000000000001px;
    border-radius: 4px;
    background-image: url("data:image/svg+xml,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' %3e %3cpath d='M1 4C1 2.34315 2.34315 1 4 1H16C17.6569 1 19 2.34315 19 4V16C19 17.6569 17.6569 19 16 19H4C2.34315 19 1 17.6569 1 16V4Z' fill='white' /%3e %3cpath fill='black' fill-rule='evenodd' clip-rule='evenodd' d='M0.25 4C0.25 1.92893 1.92893 0.25 4 0.25H16C18.0711 0.25 19.75 1.92893 19.75 4V16C19.75 18.0711 18.0711 19.75 16 19.75H4C1.92893 19.75 0.25 18.0711 0.25 16V4ZM4 1.75C2.75736 1.75 1.75 2.75736 1.75 4V16C1.75 17.2426 2.75736 18.25 4 18.25H16C17.2426 18.25 18.25 17.2426 18.25 16V4C18.25 2.75736 17.2426 1.75 16 1.75H4Z' /%3e %3c/svg%3e");
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__form-check .fd-form-check__label {
    color: var(--dark-blue);

    font-size: 14px;
    min-height: 22.400000000000002px;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0px;
    text-transform: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__form-check .fd-form-check__checkmark::after {
    background-size: 12px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='black' width='12' height='10' viewBox='0 0 11.51 8.2' %3e %3ctitle%3echeck%3c/title%3e %3cpath d='M4.05%2c8.2A.74.74%2c0%2c0%2c1%2c3.52%2c8L.22%2c4.68A.75.75%2c0%2c0%2c1%2c1.28%2c3.62l3.3%2c3.3A.75.75%2c0%2c0%2c1%2c4.58%2c8%2c.74.74%2c0%2c0%2c1%2c4.05%2c8.2Z' /%3e %3cpath d='M4.06%2c8.2A.74.74%2c0%2c0%2c1%2c3.53%2c8a.75.75%2c0%2c0%2c1%2c0-1.06l6.7-6.7a.75.75%2c0%2c0%2c1%2c1.06%2c1.06L4.59%2c8A.74.74%2c0%2c0%2c1%2c4.06%2c8.2Z' /%3e %3c/svg%3e");
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__footer {
    flex: 0 1 auto;
    margin-top: 10px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}

@media (max-width: 767px) {
    [data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__footer {
        margin-top: 25px;
    }
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__button {
    color: #ffffff;
    width: auto;
    border: 1px solid var(--orange);
    display: inline-block;
    padding: 12px 28px;
    font-size: 13px;
    background: var(--orange);
    text-align: center;
    font-family: Inter;
    font-weight: 700;
    line-height: 20px;
    white-space: nowrap;
    border-radius: 4.6000000000000005px;
    letter-spacing: 0.1px;
    text-transform: none;
}

@media (max-width: 767px) {
    [data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__button {
        width: 100%;
    }
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__success {
    display: none;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f[data-ff-stage="success"] .ff-637662ccd6bfc824ac69bc5f__success {
    display: block;
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__success-message {
    color: var(--dark-blue);

    width: 100%;
    display: block;
    font-size: 16px;
    word-wrap: anywhere;
    min-height: 1.6em;
    text-align: center;
    word-break: break-word;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    line-height: 1.6;
    white-space: normal;
    overflow-wrap: break-word;
    letter-spacing: 0.1px;
    pointer-events: auto;
    text-transform: none;
}

@media (max-width: 767px) {
    [data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__success-message {
        font-size: 16px;
    }
}

[data-ff-el="root"].ff-637662ccd6bfc824ac69bc5f .ff-637662ccd6bfc824ac69bc5f__error {
    margin: 10px 0 0 0;
}