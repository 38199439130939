:root {
  --dark-blue: #1e3160;
  --orange: #CA4F02;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

.heading-jumbo {
  margin-block: 10px;
  font-size: 64px;
  line-height: 80px;
  color: var(--dark-blue);
}

.heading-jumbo-small {
  margin-bottom: 10px 15px;
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
  color: var(--dark-blue);
}

.heading-jumbo-tiny {
  text-transform: uppercase;
  margin-block: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: var(--dark-blue);
}

h1 {
  color: var(--dark-blue);
  margin-block: 20px 15px;
  font-size: 44px;
  line-height: 62px;
}

h2 {
  color: var(--dark-blue);
  font-size: 36px;
  line-height: 42px;
  margin-block: 10px;
}

h3 {
  color: var(--dark-blue);
  font-size: 32px;
  line-height: 36px;
  margin-block: 10px;
}

h4 {
  color: var(--dark-blue);
  margin-block: 10px;
  font-size: 24px;
  line-height: 38px;
}

h5 {
  color: var(--dark-blue);
  margin-block: 10px;
  font-size: 20px;
  line-height: 34px;
}

h6 {
  color: var(--dark-blue);
  margin-block: 10px;
  font-size: 16px;
  line-height: 28px;
}

p.p-bigger {
  font-size: 20px;
  line-height: 34px;
  color: var(--dark-blue);
}

p {
  color: var(--dark-blue);
  margin-bottom: 10px;
  line-height: 24px;
}

p.p-de-emphasized {
  color: #636f88;
  text-align: center;
  line-height: 24px;
  margin-bottom: 10px;
}

p.p-smaller {
  color: var(--dark-blue);
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 10px;
}

p.p-tiny {
  color: var(--dark-blue);
  font-size: 12px;
  line-height: 16px;
  padding-top: 20px;
}

p.subtitle {
  font-size: 1.3em;
  font-weight: 700;
}

.label {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 12px;
  line-height: 20px;
  color: var(--dark-blue);
}