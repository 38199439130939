footer {
    background-color: var(--dark-blue);
    color: white;
    padding-inline: 60px;
    padding-block: 60px 20px;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #eeeeee99;

        .social-links-container {
            display: flex;
            flex-direction: row;
            gap: 16px;
        }
    }

    .bottom {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 40px 60px 60px 60px;

        a:is(*) {
            text-align: center;
            color: white;
            text-decoration: none;

            &:hover {
                color: var(--orange);
            }
        }
    }
}

@media (max-width: 610px) {
    footer {
        padding-inline: 20px;
        
        .bottom {
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 40px;
        }
    }


}