.gah-email-fld {
    width: 100%;
    height: 60px;
    padding-left: 12px;
    border-radius: 8px;
    border: 1px solid #c5cee0;
    font-size: 16px;
    color: #8f9bb3;
}

.gah-email-txt {
    color: #8f9bb3;
}

.gah-error-fld {
    display: none;
    color: #e00202;
    font-size: 12px;
    line-height: 28px;
}
.gah-error-fld.error {
    display: block;
}

#gah-submit-btn-2 {
    margin-top: 20px;
}

.modal-container:not(.show) {
    opacity: 0;
    pointer-events: none;
}

.modal-container {
    transition: opacity 0.5s ease;

    position: fixed;
    inset: 0;
    z-index: 11;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #00000080;
}

.modal {
    position: relative;
    background-color: #fff;
    border-radius: 12px;
    height: max-content;
    max-height: 500px;
    font-family: Inter, sans-serif;
    display: block;
    overflow: scroll;
    box-shadow: 0 4px 20px 1px #00000052;

    width: 600px;
    margin: auto;
    padding-top: 40px;
    padding-left: 60px;
    padding-right: 60px;
    position: absolute;

    .modal-form-label {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        margin-top: 20px;

        .label-text {
            margin-left: 10px;
            color: var(--dark-blue);
        }
    }
}

.modal::-webkit-scrollbar {
    display: none;
}

.close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

@media (max-width: 620px) {
    .modal {
        width: 100%;
        height: max-content;
        max-height: 80vh;

        padding: 20px;
    }
}
