.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 200px; */
    padding: 30px 50px;
    /* background-color: var(--dark-blue); */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

.header-logo {
    max-width: 200px;
    object-fit: contain;
    object-position: center;
}

@media (max-width: 990px) {
    .header {
        padding: 25px 30px;
    }
}

@media (max-width: 610px) {
    .header {
        padding: 20px;
    }

    .header-logo {
        width: 50%;
    }
}