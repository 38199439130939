.social-link {
    max-width: 32px;
    max-height: 32px;

    width: 6vw;
    height: 6vw;

    display: inline-block;

    img {
        width: 100%;
        height: 100%;
    }
}