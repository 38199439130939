.step-card {
    padding: 30px;
    backdrop-filter: blur(10px);
    
    // this is a bad implementation 👇
    background-color: #ffffff7d;
    background-image: (linear-gradient(#ffffff21, #ffffff21));

    border-radius: 12px;
    position: relative;
    box-shadow: 0 3px 14px 7px #01002e0d;


    img {
        width: 100px;
        height: 100px;
    }
}