.accordion-container {
    position: relative;
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    border-radius: 12px;
}

.accordion-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    width: 100%;

    .heading-wrapper {
        cursor: pointer;
        padding: 20px;
        width: 100%;

        .heading-text {
            font-weight: 700;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            img {
                width: 11px;
                transform: rotate(0deg);
                transition: transform ease 0.5s;
            }
        }
    }

    .body-container {
        max-height: 0px;
        overflow: hidden;
        transition: max-height ease 0.5s;

        p {
            padding: 20px;
            // padding-bottom: 0px;
        }
    }

    &.open {
        .heading-wrapper img {
            transform: rotate(180deg);
        }
        .body-container {
            // max-height: 200px;
        }
    }
}
